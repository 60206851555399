import React, { Component } from 'react';
import { Layout } from 'layouts';
import {
  PageHeader,
  TechnologyPartners,
  BrandsWorkedWithUs,
} from '@components';
import {
  BetterThanOther,
  MultiColumnList,
  ServiceCatalogues,
} from '@components/industries';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/industries';

class Event extends Component {
  render() {
    const event = pageContent.subPage.event;
    return (
      <Layout>
        <PageHeader image={event.headerImage} />
        <Heading
          title={event.title}
          overview={event.overview}
          noHeadingIcon={true}
        />
        <BetterThanOther content={event.betterThanOther} />
        <MultiColumnList content={event.features} />
        <MultiColumnList content={event.solutions} />
        <ServiceCatalogues items={event.serviceCatalogues} />
        <PageNavigation
          prev={pageContent.navigation.prev}
          next={pageContent.navigation.next}
        />
        <TechnologyPartners />
        <BrandsWorkedWithUs />
      </Layout>
    );
  }
}

export default Event;
